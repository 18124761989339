
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    menuController,
    IonButtons,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, menu, checkmarkCircle } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";
import router from "@/router";
import moment from "moment";

import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";
import { dbUser, dbCustomers, dbDocuments, dbRichieste, dbPresenze, dbTimbrature } from "@/services/localbase";

import apiAppuntamentiSquadre from "@/services/appuntamenti_squadre";
import apiAppuntamenti from "@/services/appuntamenti";
import apiAppuntamentiCustom from "@/custom/services/appuntamenti";
import apiUsers from "@/services/users";

import ProgrammaLavoroDetail from "@/components/programma_lavoro/ProgrammaLavoroDetail.vue";

export default {
    name: "ProgrammaLavoro",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const icons = ["broom", "tree", "circle-radiation", "soap"];
        const base_icon = "calendar";
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;

        const appuntamentiToShow = ref([]);
        const calendarRef = ref(null);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const appuntamentiSenzaRapportino = ref([]);
        const showAppuntamentiPassati = ref(false);
        const loadingAppuntamentiPassati = ref(false);
        async function loadAppuntamentiSenzaRapportino() {
            loadingAppuntamentiPassati.value = true;
            try {
                const res = await apiAppuntamentiCustom.getAppuntamentiNoRapportino(userID);
                if (res.status === 200 && res.data.status === 0) {
                    openToast(`${res.data.txt}`, "toast_danger");
                } else if (res.status === 200 && res.data.status === 1) {
                    appuntamentiSenzaRapportino.value = res.data.data;

                    // mostro modale appuntamenti passati
                    if (res.data.data.length > 0) {
                        showAppuntamentiPassati.value = true;
                    }
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti senza rapportino compilato", "toast_danger");
                }
            } catch (error) {
                appuntamentiSenzaRapportino.value = [];
                openToast("Errore durante la richiesta degli appuntamenti senza rapportino compilato", "toast_danger");
            } finally {
                loadingAppuntamentiPassati.value = false;
            }
        }

        /* function setIcon(appuntamento) {
            console.log(appuntamento.projects_type);
            if (appuntamento.projects_type && appuntamento.projects_type != 9) {
                return icons[appuntamento.projects_type - 1];
            } else {
                return base_icon;
            }
        } */
        function setIcon(appuntamento) {
            if (appuntamento.projects_type && appuntamento.projects_type != 9) {
                // Calcola l'indice utilizzando l'operatore modulo per garantire un indice valido
                const index = (appuntamento.projects_type - 1) % icons.length;
                return icons[index];
            } else {
                return base_icon;
            }
        }

        async function controlloAppuntamenti(appuntamentiVisualizzati) {
            if (appuntamentiVisualizzati.length != 0) {
                //Estraggo gli id degli appuntamenti e cerco i rapportini per questi appuntamenti_id
                const appuntamentiId = [];
                appuntamentiVisualizzati.forEach((el) => {
                    appuntamentiId.push(el.appuntamenti_id);
                });

                if (appuntamentiId.length != 0) {
                    const ids = `(${appuntamentiId.toString()})`;

                    try {
                        const res = await apiAppuntamenti.getRapportiniAppuntamenti(ids, userID);
                        if (res.status === 0) {
                            if (res.data.length != 0) {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                    //Se l'appuntamento è legato ad un rapportino lo marco con rapportino_creato
                                    const appuntamentoId = appuntamento.appuntamenti_id;
                                    const hasRapportino = res.data.some((rapportino) => rapportino.rapportini_appuntamento_id === appuntamentoId);

                                    if (hasRapportino) {
                                        appuntamento.rapportino_creato = true;
                                    } else {
                                        appuntamento.rapportino_creato = false;
                                    }
                                });
                            } else {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                });
                            }
                        } else {
                            openToast("Errore durante il controllo degli appuntamenti", "toast_danger");
                        }
                    } catch (error) {
                        console.log(error);
                        openToast("Errore durante il controllo degli appuntamenti", "toast_danger");
                    }
                }
            }
        }

        /**
         * ! Blocca creazione rapportino se l'appuntamento è almeno di un mese precedente e siamo oltre l'8 del mese corrente
         */
        function bloccaCreazioneRapportino(appuntamento) {
            const appuntamentoGiorno = moment(appuntamento.appuntamenti_giorno);
            const now = moment();

            if (now.date() <= 8) {
                // L'appuntamento è di due mesi fa o di un anno precedente
                const mesePrecedente = (now.month() === 0) ? 11 : now.month() - 1;
                const annoPrecedente = (now.month() === 0) ? now.year() - 1 : now.year();

                if (appuntamentoGiorno.year() < annoPrecedente || 
                    (appuntamentoGiorno.year() === annoPrecedente && appuntamentoGiorno.month() < mesePrecedente)) {
                    return false; // Blocco creazione
                } else {
                    return true; // Permesso di creare
                }
            } else {
                // L'appuntamento è del mese precedente o di un anno precedente
                const mesePrecedente = (now.month() === 0) ? 11 : now.month() - 1;
                const annoPrecedente = (now.month() === 0) ? now.year() - 1 : now.year();

                if (appuntamentoGiorno.year() < annoPrecedente || 
                    (appuntamentoGiorno.year() === annoPrecedente && appuntamentoGiorno.month() <= mesePrecedente)) {
                    return false; // Blocco creazione
                } else {
                    return true; // Permesso di creare
                }
            }
        }


        /**
         * ! Passing appuntamento object ProgrammaLavoroDetail page
         */
        async function openDetailModal(appuntamento) {
            const check = bloccaCreazioneRapportino(appuntamento);
            if (!check) {
                openToast("La creazione di rapportini per appuntamenti del mese precedente è consentita fino al giorno 8 di questo mese", "toast_danger");
                return;
            }

            const modal = await modalController.create({
                component: ProgrammaLavoroDetail,
                componentProps: {
                    data: appuntamento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                controlloAppuntamenti(appuntamentiToShow.value);
                //Imposto appuntamento come annullato per nascondere buttons alla prossima apertura del dettaglio
                if (detail && detail.data) {
                    appuntamento.appuntamenti_annullato = 1;
                    //Chiudo modale appuntamenti passati
                    showAppuntamentiPassati.value = false;
                    loadAppuntamentiSenzaRapportino();
                }
            });
            return modal.present();
        }

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        /**
         * ! Get all appuntamenti
         */
        const appuntamenti = ref([]);
        async function loadAppuntamenti() {
            loading.value = true;
            try {
                const res = await apiAppuntamentiSquadre.getAppuntamenti(userID);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(new Date()); // Add state to store selected day

        const setAttributes = computed(() => {
            return appuntamenti.value.map((appuntamento) => ({
                key: `appuntamento.${appuntamento.appuntamenti_id}`,
                highlight: {
                    //color: "blue",
                    color: moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD") ? "green" : "blue",
                    fillMode: "solid",
                },
                dates: appuntamento.appuntamenti_giorno,
                customData: appuntamento,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const dayClicked = (day) => {
            //console.log(day);
            selectedDay.value = day;
            appuntamentiToShow.value = [];
            appuntamentiToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);
            //controllo se gli appuntamenti di oggi hanno già un rapportino creato per mostrare spunta
            if (appuntamentiToShow.value.length != 0) {
                controlloAppuntamenti(appuntamentiToShow.value);

                //Controllo appuntamento per cambiare colore giornata
                const appuntamentiTemp = day.attributes;
                //console.log(appuntamentiTemp);
                const tuttiConRapportinoCreato = appuntamentiToShow.value.every((appuntamento) => appuntamento.rapportino_creato === true);
                /*if (tuttiConRapportinoCreato) {
                    console.log(day);
                    console.log(tuttiConRapportinoCreato);
                }*/
            }
        };

        const todayAppointments = ref([]);
        async function loadData(startDate: string, endDate: string) {
            appuntamentiToShow.value = [];
            //console.log(startDate, endDate);
            try {
                const res = await apiAppuntamenti.getAppuntamenti(userID, startDate, endDate);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                    //Popolo array degli appuntamenti da mostrare con quelli di oggi per vederli subito
                    const todayApp = res.data.filter((el) => {
                        if (moment(el.appuntamenti_giorno).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")) {
                            return el;
                        }
                    });
                    appuntamentiToShow.value = todayApp;
                    controlloAppuntamenti(appuntamentiToShow.value);
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! GET CURRENT MONTH APPOINTMENTS
         * @param page current month and yer object
         *
         */
        const loadCurrentMonthData = (page) => {
            const currentMonth = page.month;
            const currentYear = page.year;

            const momentStartDate = moment([currentYear, currentMonth - 1]);
            const momentEndDate = moment(momentStartDate).endOf("month");
            const formatStart = momentStartDate.format("YYYY-MM-DD");
            const formatEnd = momentEndDate.format("YYYY-MM-DD");

            loadData(formatStart, formatEnd);
        };

        const moveDate = async () => {
            await calendarRef.value.move(`1983-01-21`);
            await calendarRef.value.focusDate(`1983-01-21`, {
                transition: "slide-h",
                position: 1,
            });
        };

        const selectToday = () => {
            const today = moment(new Date()).format("YYYY-MM-DD");
            //console.log(today);
            const todayCalendarEl: HTMLElement = document.querySelector(`.id-${today}`);
            //console.log(todayCalendarEl);
            todayCalendarEl.click();
        };


        /**
         * 
         * ! Check if current user is active ore not
         * 
         */
         async function deleteDb() {
            /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbDocuments.delete().then((response) => {
                console.log(response);
            });
            dbRichieste.delete().then((response) => {
                console.log(response);
            });
            dbPresenze.delete().then((response) => {
                console.log(response);
            });
            dbTimbrature.delete().then((response) => {
                console.log(response);
            });
        }
        
         function logOut() {
            deleteDb().then(() => {
                localStorage.clear();
                router.replace("/login");
            });
        }

        async function checkUserActive() {
            try {
                const response = await apiUsers.getUser(userID);
                if (response.status === 200 && response.data.data.length != 0) {
                    const loggedUser = response.data.data;
                    if (loggedUser.users_active != "1") {
                        logOut();
                    }
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei dati dell'utente", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei dati dell'utente", "toast_danger");
            }
        }

        /* Ad ogni accesso alla pagina richiedo i dati  degli appuntamenti senza rapportino */
        onIonViewWillEnter(() => {
            checkUserActive();
            loadAppuntamentiSenzaRapportino();
        });

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            /*loadAppuntamenti();
            console.log(calendarRef);
            //moveDate()todayCalendarEl
            selectToday();*/
            //selectToday();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            loadAppuntamenti,
            dateFormat,
            appuntamenti,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            appuntamentiToShow,
            riferimentoCliente,
            indirizzoCliente,
            checkmarkCircle,
            icons,
            setIcon,
            loadCurrentMonthData,
            calendarRef,
            todayAppointments,
            //appuntamenti passati
            loadingAppuntamentiPassati,
            appuntamentiSenzaRapportino,
            showAppuntamentiPassati,
        };
    },
};
